<template>
  <div class="statistics">
    <HeaderMenu />
    <div class="container py-4">
      <div v-if="data">
        <h1 class="h5 fw-medium mb-3 text-center">
          {{ $t("__countBed") }}
          <span class="ps-2">{{ data.totalDevices }}</span>
        </h1>
        <p class="text-center">
          <span class="fw-medium pe-2 d-block d-sm-inline-block"
            >{{ $t("__statisticsDateTimeRange") }} ：</span
          >
          <span class="d-inline-block">{{
            $getTimeZoneDate(data.start, timezone, 'LLLL')
          }}</span>
          ~
          <span class="d-inline-block">{{
            $getTimeZoneDate(data.end, timezone, 'LLLL')
          }}</span>
        </p>
        <div class="row g-2 g-sm-4 data-card align-items-stretch justify-content-center">
          <div class="col-6 col-lg-4 col-xl-3 col-xxl-auto">
            <div class="card">
              <div class="card-body">
                <h2 class="h5 fw-medium mb-3">{{ $t("__LVBStatistics") }}</h2>
                <p>
                  {{ leaveTypeName }}
                </p>
                <PieChart
                  :svgPaddingX="svgPaddingX"
                  :svgPaddingY="svgPaddingY"
                  :data="data.leave_bed.count"
                  :count="data.totalDevices"
                />
              </div>
              <div class="accordion accordion-flush" id="LVBStatisticsAccordion" v-if="data.leave_bed.count">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="LVBStatistics_header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-LVBStatistics" aria-expanded="false" aria-controls="flush-LVBStatistics">
                      {{ $t('__information') }}
                    </button>
                  </h2>
                  <div id="flush-LVBStatistics" class="accordion-collapse collapse" aria-labelledby="LVBStatistics_header" data-bs-parent="#LVBStatisticsAccordion">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item fw-bold">{{$t('__bedNoName')}}</li>
                        <li class="list-group-item" v-for="(item, index) in data.leave_bed.devices" :key="'leave_bed' + index">{{item.bed_number + '/' + item.resident_name}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-4 col-xl-3 col-xxl-auto">
            <div class="card">
              <div class="card-body">
                <h2 class="h5 fw-medium mb-3">{{ $t("__LYBStatistics") }}</h2>
                <p>
                  {{ lyingTypeName }}
                </p>
                <PieChart
                  :svgPaddingX="svgPaddingX"
                  :svgPaddingY="svgPaddingY"
                  :data="data.lying.count"
                  :count="data.totalDevices"
                />
              </div>
              <div class="accordion accordion-flush" id="LYBStatisticsAccordion" v-if="data.lying.count">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="LYBStatistics_header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-LYBStatistics" aria-expanded="false" aria-controls="flush-LYBStatistics">
                      {{ $t('__information') }}
                    </button>
                  </h2>
                  <div id="flush-LYBStatistics" class="accordion-collapse collapse" aria-labelledby="LYBStatistics_header" data-bs-parent="#LYBStatisticsAccordion">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item fw-bold">{{$t('__bedNoName')}}</li>
                        <li class="list-group-item" v-for="(item, index) in data.lying.devices" :key="'lying' + index">{{item.bed_number + '/' + item.resident_name}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-4 col-xl-3 col-xxl-auto">
            <div class="card">
              <div class="card-body">
                <h2 class="h5 fw-medium mb-3">{{ $t("__sleepStatistics") }}</h2>
                <p>
                  {{ sleepTypeName }}
                </p>
                <PieChart
                  :svgPaddingX="svgPaddingX"
                  :svgPaddingY="svgPaddingY"
                  :data="data.sleep.count"
                  :count="data.totalDevices"
                />
              </div>
              <div class="accordion accordion-flush" id="sleepStatisticsAccordion" v-if="data.sleep.count">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="sleepStatistics_header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-sleepStatistics" aria-expanded="false" aria-controls="flush-sleepStatistics">
                      {{ $t('__information') }}
                    </button>
                  </h2>
                  <div id="flush-sleepStatistics" class="accordion-collapse collapse" aria-labelledby="sleepStatistics_header" data-bs-parent="#sleepStatisticsAccordion">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item fw-bold">{{$t('__bedNoName')}}</li>
                        <li class="list-group-item" v-for="(item, index) in data.sleep.devices" :key="'sleep' + index">{{item.bed_number + '/' + item.resident_name}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-4 col-xl-3 col-xxl-auto">
            <div class="card">
              <div class="card-body">
                <h2 class="h5 fw-medium mb-3">
                  {{ $t("__RRNotifyStatistics") }}
                </h2>
                <p>
                  {{ $t('__allDayRespiration', {nub: data.settings ? data.settings.respiration_count : setting.respiration_count }) }}
                </p>
                <PieChart
                  :svgPaddingX="svgPaddingX"
                  :svgPaddingY="svgPaddingY"
                  :data="data.respiration.count"
                  :count="data.totalDevices"
                />
              </div>
              <div class="accordion accordion-flush" id="RRNotifyStatisticsAccordion" v-if="data.respiration.count">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="RRNotifyStatistics_header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-RRNotifyStatistics" aria-expanded="false" aria-controls="flush-RRNotifyStatistics">
                      {{ $t('__information') }}
                    </button>
                  </h2>
                  <div id="flush-RRNotifyStatistics" class="accordion-collapse collapse" aria-labelledby="RRNotifyStatistics_header" data-bs-parent="#RRNotifyStatisticsAccordion">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item fw-bold">{{$t('__bedNoName')}}</li>
                        <li class="list-group-item" v-for="(item, index) in data.respiration.devices" :key="'respiration' + index">{{item.bed_number + '/' + item.resident_name}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center mb-3">
        <h3>{{$t('__reportExportingNoteInfo')}}</h3>
      </div>
      <div class="text-center mt-3">
        <button
          type="button"
          class="btn btn-outline-primary rounded-pill"
          data-bs-toggle="modal"
          data-bs-target="#statisticalConditionsModal"
          @click="showStatisticalConditionsSet"
        >
          <i class="bi bi-sliders me-2"></i>
          {{ $t("__statisticalConditionsSet") }}
        </button>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <button
          @click="beforeDay"
          :class="{
            'd-none':
            $getTimeZoneDate(new Date().addDays(-6), timezone, 'YYYY-MM-DD') ===
              ended_at,
          }"
          class="btn btn-primary rounded-pill mb-3 mx-2"
        >
          {{ $t('__beforeDay') }}
        </button>
        <button
          @click="afterDay"
          :class="{
            'd-none': $getTimeZoneDate(new Date(), timezone, 'YYYY-MM-DD') === ended_at,
          }"
          class="btn btn-primary rounded-pill ms-2 mb-3 mx-2"
        >
          {{ $t('__afterDay') }}
        </button>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" id="statisticalConditionsModal" ref="statisticalConditionsModal">
      <div class="modal-dialog" :class="lang === 'zh' ? 'modal-custom-size-zh' : 'modal-custom-size-en'">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-medium">{{ $t("__statisticalConditionsSet") }}</h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              class="
                d-flex
                flex-column flex-md-row
                justify-content-center
                cms-style
                side-tab
              "
              style="overflow-x: auto;"
            >
              <div class="left-col">
                <ul class="list-group flex-md-column flex-row">
                  <li class="list-group-item">
                    <button
                      class="btn btn-link nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#LVBStatistics"
                      type="button"
                      role="tab"
                      aria-controls="LVBStatistics"
                      aria-selected="true"
                    >
                      {{ $t("__LVBStatistics") }}
                    </button>
                  </li>
                  <li class="list-group-item">
                    <button
                      class="btn btn-link nav-link"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#LYBStatistics"
                      type="button"
                      role="tab"
                      aria-controls="LYBStatistics"
                      aria-selected="true"
                    >
                      {{ $t("__LYBStatistics") }}
                    </button>
                  </li>
                  <li class="list-group-item">
                    <button
                      class="btn btn-link nav-link"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#sleepStatistics"
                      type="button"
                      role="tab"
                      aria-controls="sleepStatistics"
                      aria-selected="true"
                    >
                      {{ $t("__sleepStatistics") }}
                    </button>
                  </li>
                  <li class="list-group-item">
                    <button
                      class="btn btn-link nav-link"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#RRNotifyStatistics"
                      type="button"
                      role="tab"
                      aria-controls="RRNotifyStatistics"
                      aria-selected="true"
                    >
                      {{ $t("__RRNotifyStatistics") }}
                    </button>
                  </li>
                </ul>
              </div>
              <div class="right-col">
                <div class="tab-content cms-style h-100">
                  <div
                    class="tab-pane fade show active h-100"
                    id="LVBStatistics"
                    role="tabpanel"
                    aria-labelledby="LVBStatistics-tab"
                  >
                    <div class="px-3 px-md-5 py-3">
                      <div class="form-check d-flex align-items-center mb-3">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="LVBStatistics"
                          id="LVBStatisticsDaytime"
                          value="day"
                          v-model="setting.leave_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="LVBStatisticsDaytime"
                        >
                          {{ $t('__daytimeLeaveTheBedTimeOutHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.leave_count"
                            min="0"
                          />
                          {{ $t('__times') }}
                        </label>
                      </div>
                      <div class="form-check d-flex align-items-center mb-3">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="LVBStatistics"
                          id="LVBStatisticsAtNight"
                          value="night"
                          v-model="setting.leave_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="LVBStatisticsAtNight"
                        >
                          {{ $t('__nightTimeLeaveTheBedTimeOutHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.leave_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__times') }}
                        </label>
                      </div>
                      <div class="form-check d-flex align-items-center">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="LVBStatistics"
                          id="LVBStatisticsAllDay"
                          value="all"
                          v-model="setting.leave_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="LVBStatisticsAllDay"
                        >
                          {{ $t('__allDayLeaveTheBedTimeOutHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            aria-describedby="passwordHelpInline"
                            style="width: 60px"
                            v-model="setting.leave_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__times') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade h-100"
                    id="LYBStatistics"
                    role="tabpanel"
                    aria-labelledby="LVBStatistics-tab"
                  >
                    <div class="px-3 px-md-5 py-3">
                      <div class="form-check d-flex align-items-center mb-3">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="LYBStatistics"
                          id="LYBStatisticsDaytime"
                          value="day"
                          v-model="setting.lying_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="LYBStatisticsDaytime"
                        >
                          {{ $t('__daytimeLyingOnTheBedTimeOutHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.lying_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__times') }}
                        </label>
                      </div>
                      <div class="form-check d-flex align-items-center mb-3">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="LYBStatistics"
                          id="LYBStatisticsAtNight"
                          value="night"
                          v-model="setting.lying_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="LYBStatisticsAtNight"
                        >
                          {{ $t('__nightTimeLyingOnTheBedTimeOutHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.lying_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__times') }}
                        </label>
                      </div>
                      <div class="form-check d-flex align-items-center">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="LYBStatistics"
                          id="LYBStatisticsAllDay"
                          value="all"
                          v-model="setting.lying_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="LYBStatisticsAllDay"
                        >
                          {{ $t('__allDayLyingOnTheBedTimeOutHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.lying_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__times') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade h-100"
                    id="sleepStatistics"
                    role="tabpanel"
                    aria-labelledby="LVBStatistics-tab"
                  >
                    <div class="px-3 px-md-5 py-3">
                      <div class="form-check d-flex align-items-center mb-3">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="sleepStatistics"
                          id="sleepStatisticsDaytime"
                          value="onBedDay"
                          v-model="setting.sleep_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="sleepStatisticsDaytime"
                        >
                          {{ $t('__daytimeOnBed') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.sleep_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__hour') }}
                        </label>
                      </div>
                      <div class="form-check d-flex align-items-center mb-3">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="sleepStatistics"
                          id="sleepStatisticsAtNight"
                          value="onBedNight"
                          v-model="setting.sleep_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="sleepStatisticsAtNight"
                        >
                          {{ $t('__nightTimeOnBedHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.sleep_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__hour') }}
                        </label>
                      </div>
                      <div class="form-check d-flex align-items-center">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          name="sleepStatistics"
                          id="sleepStatisticsAllDay"
                          value="sleepNight"
                          v-model="setting.sleep_type"
                          :disabled="!hasStatistics"
                        />
                        <label
                          class="form-check-label"
                          for="sleepStatisticsAllDay"
                        >
                          {{ $t('__nightTimeSleepOnBedHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.sleep_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__hour') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade h-100"
                    id="RRNotifyStatistics"
                    role="tabpanel"
                    aria-labelledby="RRNotifyStatistics-tab"
                  >
                    <div class="px-3 px-md-5 py-3">
                      <div class="d-flex align-items-center">
                        <label class="form-label">
                          {{ $t('__allDayRespirationHTML') }}
                          <input
                            type="number"
                            class="form-control d-inline-block mx-2"
                            style="width: 60px"
                            v-model="setting.respiration_count"
                            min="0"
                            :disabled="!hasStatistics"
                          />
                          {{ $t('__times') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-danger text-center mt-2" v-if="is_reportSetting_invalid">
              {{ reportSetting_feedback_message }}
            </div>
          </div>
          <div class="modal-footer" v-if="hasStatistics">
            <button
              type="button"
              class="btn btn-outline-primary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button @click="submitReportSetting" type="button" class="btn btn-primary rounded-pill">
              {{ $t("__save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import PieChart from '@/components/chart/PieChart.vue'
import i18n from '@/lang/lang.js'
import { report } from '@/http/api/report.js'
import { mapState, mapMutations } from 'vuex'
import { Modal } from 'bootstrap'

export default {
  name: 'ReportStatistics',
  components: { HeaderMenu, PieChart },
  data () {
    return {
      lang: i18n.locale,
      data: null,
      svgPaddingX: 20,
      svgPaddingY: 20,
      bootstrap_modal: null,
      is_reportSetting_invalid: false,
      reportSetting_feedback_message: i18n.t('__reportSettingIsRequired'),
      setting: {
        leave_type: 'night',
        leave_count: 3,
        lying_type: 'all',
        lying_count: 3,
        sleep_type: 'sleepNight',
        sleep_count: 6,
        restless_count: 2,
        respiration_count: 3
      }
    }
  },
  computed: {
    ...mapState(['token', 'hasStatistics', 'timezone']),
    ended_at () {
      const vm = this
      return vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
    },
    leaveTypeName () {
      const vm = this
      const setting = (vm.data && vm.data.settings) ? vm.data.settings : vm.setting
      let name = ''
      switch (setting.leave_type) {
        case 'day':
          name = i18n.t('__daytimeLeaveTheBedTimeOut', { nub: setting.leave_count })
          break
        case 'night':
          name = i18n.t('__nightTimeLeaveTheBedTimeOut', { nub: setting.leave_count })
          break
        case 'all':
          name = i18n.t('__allDayLeaveTheBedTimeOut', { nub: setting.leave_count })
          break
      }
      return name
    },
    lyingTypeName () {
      const vm = this
      const setting = (vm.data && vm.data.settings) ? vm.data.settings : vm.setting
      let name = ''
      switch (setting.lying_type) {
        case 'day':
          name = i18n.t('__daytimeLyingOnTheBedTimeOut', { nub: setting.lying_count })
          break
        case 'night':
          name = i18n.t('__nightTimeLyingOnTheBedTimeOut', { nub: setting.lying_count })
          break
        case 'all':
          name = i18n.t('__allDayLyingOnTheBedTimeOut', { nub: setting.lying_count })
          break
      }
      return name
    },
    sleepTypeName () {
      const vm = this
      const setting = (vm.data && vm.data.settings) ? vm.data.settings : vm.setting
      let name = ''
      switch (setting.sleep_type) {
        case 'onBedDay':
          name = i18n.t('__daytimeOnBed', { nub: setting.sleep_count })
          break
        case 'onBedNight':
          name = i18n.t('__nightTimeOnBed', { nub: setting.sleep_count })
          break
        case 'sleepNight':
          name = i18n.t('__nightTimeSleepOnBed', { nub: setting.sleep_count })
          break
      }
      return name
    }
  },
  watch: {
    token: {
      handler: function () {
        this.getStatistics()
        this.getStatisticsFilter()
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    beforeDay () {
      this.ended_at = new Date(this.ended_at).addDays(-1).format('yyyy-MM-dd')
      this.getStatistics()
    },
    afterDay () {
      this.ended_at = new Date(this.ended_at).addDays(1).format('yyyy-MM-dd')
      this.getStatistics()
    },
    getStatistics () {
      const vm = this
      var config = Object.assign({}, vm.token)
      vm.Loading()
      config.params = {}
      config.params.ended_at = vm.ended_at
      report.getStatistics(config).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.data = Array.isArray(res.data.data) ? null : res.data.data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    getStatisticsFilter () {
      const vm = this
      const setting = {
        leave_type: 'night',
        leave_count: 3,
        lying_type: 'all',
        lying_count: 3,
        sleep_type: 'sleepNight',
        sleep_count: 6,
        restless_count: 2,
        respiration_count: 3
      }
      report.getStatisticsFilter(vm.token).then(res => {
        if (res.status <= 201) {
          vm.setting = Array.isArray(res.data.data) ? setting : res.data.data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    submitReportSetting () {
      const vm = this
      const settingData = vm.setting
      if (!settingData.leave_type || !settingData.leave_count) {
        vm.is_reportSetting_invalid = true
        return false
      }
      if (!settingData.lying_type || !settingData.lying_count) {
        vm.is_reportSetting_invalid = true
        return false
      }
      if (!settingData.sleep_type || !settingData.sleep_count) {
        vm.is_reportSetting_invalid = true
        return false
      }
      if (!settingData.respiration_count) {
        vm.is_reportSetting_invalid = true
        return false
      }
      vm.Loading()

      delete settingData.ended_at
      delete settingData.started_at

      report.updateStatisticsFilter({ filter: settingData }, vm.token).then(res => {
        if (res.status <= 201) {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__editSuccess')
          })
          vm.Alerted()
          vm.bootstrap_modal.hide()
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      }).catch(err => {
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: err
        })
        vm.Alerted()
        vm.Loaded()
      })
    },
    showStatisticalConditionsSet () {
      const vm = this
      vm.$store.dispatch('getStatistics').then(() => {
        // if (vm.hasStatistics) {
        // }
      })
    }
  },
  created () {
  },
  mounted () {
    const vm = this
    if (vm.token.headers.Authorization) {
      vm.getStatistics()
      vm.getStatisticsFilter()
    }
    vm.$nextTick(function () {
      vm.bootstrap_modal = new Modal(vm.$refs.statisticalConditionsModal)
      vm.$refs.statisticalConditionsModal.addEventListener('show.bs.modal', () => {
        vm.getStatisticsFilter()
      })
    })
  }
}
</script>

<style lang="scss">
.data-card {
  > div {
    @include media-breakpoint-up(xxl) {
      width: (100% / 5);
    }
  }
}
.modal-custom-size-zh{
  max-width: 530px;
  .left-col{
    @include media-breakpoint-up(md) {
      width: 140px;
    }
  }
  .right-col{
    @include media-breakpoint-up(md) {
      white-space: nowrap !important;
    }
  }
}
.modal-custom-size-en{
  max-width: 670px;
  .left-col{
    @include media-breakpoint-up(md) {
      width: 200px;
      text-align: center;
    }
    button{
      margin: auto;
    }
  }
  .right-col{
    @include media-breakpoint-up(md) {
      white-space: nowrap !important;
    }
  }
}
</style>
